<template>
  <container-page class="mb-3">
    <bloc-simple class="flex-shrink-0 mb-2" :style="styleLeftBarLg">
      <left-bar></left-bar>
    </bloc-simple>
    <div class="mt-3 mt-lg-0 w-100" ref="rightColumn">
      <router-view></router-view>
    </div>
  </container-page>
</template>
<script>
import screenWidth from "@/mixins/screenWidth";
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import LeftBar from "@/components/affaires/LeftBar.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ContainerPage,
    BlocSimple,
    LeftBar
  },
  mixins: [screenWidth],
  methods: {
    ...mapActions({
      getData: "affaires/getData",
    })
  },
  mounted() {
    this.getData();
  },
  computed: {
    styleLeftBarLg() {
      if (this.w > 992)
        return "min-width:350px;max-height:100vh;overflow:auto;position:sticky;top:0;z-index:1020;";
      else return "max-height:50vh;overflow:auto;";
    }
  }
};
</script>
