<template>
  <div class="w-md-400">
    <div class="d-flex justify-content-center mt-2">
      <router-link
        :to="{ name: 'crm_dashboard' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem"
        :active-class="activeClass"
      >
        <base-icon name="activity" width="34" height="34" class="m-0 mb-1"></base-icon>
        <small>
          <span>Tableau de bord</span>
        </small>
      </router-link>
      <router-link
        :to="{ name: 'crm_recherche' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem"
        :active-class="activeClass"
      >
        <base-icon name="search" width="34" height="34" class="m-0 mb-1"></base-icon>
        <small>
          <span>Recherche avancée</span>
        </small>
      </router-link>
      <router-link
        :to="{ name: 'crm_dashboard_rex' }"
        class="rounded p-3 text-center d-flex flex-column align-items-center cursor-pointer"
        tag="div"
        style="width: 8rem"
        :active-class="activeClass"
      >
        <base-icon name="umbrella" width="34" height="34" class="m-0 mb-1"></base-icon>
        <small>
          <span>Retour d'expérience</span>
        </small>
      </router-link>
    </div>
    <div>
      <div class="pl-3 pr-3 pb-3" :class="qwarkBorder">
        <div class v-if="data.etats">
          <base-select
            inputText="Filtre"
            v-model.number="affaireEtat"
            :options="data.etats"
            :disabled="false"
            modeIOS
          ></base-select>

          <input-search
            text="Rechercher..."
            @reset="searchReset"
            v-model="searchText"
            :showResults="false"
            :loading="loadingSearch"
            :modeIOS="true"
          ></input-search>

          <Checkbox
            id="affairePilote1"
            class="mt-3"
            v-model="affairePilote"
            text="Afficher uniquement vos affaires"
            :iconActive="true"
          ></Checkbox>
        </div>
      </div>
      <div class>
        <div v-for="affaire in affaireFiltered" :key="affaire.id">
          <left-bar-item :data="affaire"></left-bar-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftBarItem from "@/components/affaires/LeftBarItem.vue";
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import BaseSelect from "@/components/bases/Select.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import InputSearch from "@/components/bases/InputSearch.vue";

export default {
  components: { leftBarItem, BaseSelect, Checkbox, BaseIcon, InputSearch },
  data() {
    return {
      loading: false,
      affaireEtat: null,
      affairePilote: false,
      loadingSearch: false,
      searchText: "",
    };
  },
  computed: {
    ...mapGetters({
      data: "affaires/data",
      user: "user/data",
    }),
    ...mapMultiRowFields("affaires", ["affaires"]),
    qwarkBorder: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
    affaireFiltered: function () {
      let rep = this.affaires;

      if (this.affairePilote == true) {
        rep = rep.filter((affaire) => affaire.affaire_pilote_id == this.user.id);
      }

      if (this.affaireEtat) {
        rep = rep.filter((affaire) => affaire.affaire_etat_id == this.affaireEtat || affaire.affaire_etat_id == null);
      }

      if (this.searchText !== "") {
        rep = rep.filter((affaire) => {
          let identifiant = "";
          let libelle = "";

          if (affaire.affaire_identifiant) {
            identifiant = affaire.affaire_identifiant;
          }

          if (affaire.affaire_libelle) {
            libelle = affaire.affaire_libelle;
          }

          return (
            identifiant.toLowerCase().includes(this.searchText.toLowerCase()) ||
            libelle.toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
      }

      return rep.slice().reverse();
    },
    activeClass: function () {
      if (!this.$store.getters["colors/darkMode"]) {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
  },
  methods: {
    ...mapActions({
      getAffaires: "affaires/getAffaires",
    }),
    searchReset: function () {
      this.searchText = "";
    },
  },
  mounted() {
    this.getAffaires();
  },
};
</script>
